export default function CompanyLogo() {
  return (
    <svg
      width="182"
      height="50"
      viewBox="0 0 182 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_549_2760)">
        <path
          d="M43.3731 6.62658C34.5373 -2.20918 20.1922 -2.20918 11.3564 6.62658C5.19739 12.7856 3.35228 21.6474 5.79511 29.4436L11.6683 27.4946C9.92712 21.8293 11.2785 15.4363 15.7483 10.9925C22.1672 4.57356 32.5882 4.57356 39.0072 10.9925C45.4261 17.4114 45.4261 27.8324 39.0072 34.2513C34.5633 38.6952 28.1704 40.0725 22.5311 38.3314L20.582 44.2045C28.3783 46.6474 37.24 44.8023 43.3991 38.6172C52.2088 29.8075 52.2088 15.4363 43.3731 6.62658Z"
          fill="url(#paint0_linear_549_2760)"
        />
        <path
          d="M25.5205 22.8428L11.6432 27.4686L5.77001 29.4177L0.858364 31.0549C0.156701 31.2888 -0.207124 32.0424 0.0527509 32.7181C0.598489 34.1474 2.46959 36.5643 4.80847 39.163C4.88643 39.267 4.99038 39.3709 5.09433 39.4749C5.09433 39.4749 5.09433 39.4749 5.12032 39.5008C6.28976 40.6703 8.03092 40.9562 9.48622 40.3324C9.7461 40.2285 10.006 40.4884 9.90202 40.7483C9.30431 42.1256 9.5382 43.7628 10.6037 44.9322C10.6557 44.9842 10.7076 45.0362 10.7596 45.1142C10.9155 45.2701 11.0715 45.4 11.2534 45.53C13.6962 47.7129 15.9311 49.4021 17.2565 49.8959C17.9322 50.1557 18.7118 49.7919 18.9197 49.0902L20.5569 44.1786L22.506 38.3054L27.1318 24.4281C27.4696 23.4665 26.534 22.531 25.5205 22.8428ZM17.8022 36.3304C16.6588 37.4738 14.7877 37.4738 13.6442 36.3304C12.5008 35.1869 12.5008 33.3158 13.6442 32.1724C13.6962 32.1204 13.7742 32.0424 13.8521 31.9905C14.9956 31.0289 16.7367 31.0809 17.8022 32.1724C18.8937 33.2638 18.9457 34.979 17.9841 36.1225C17.9322 36.2004 17.8802 36.2784 17.8022 36.3304Z"
          fill="url(#paint1_linear_549_2760)"
        />
        <path
          d="M13.6442 32.1724C13.6962 32.1205 13.7742 32.0425 13.8521 31.9905C14.9956 31.029 16.7367 31.081 17.8022 32.1724L26.8199 23.1548C26.5081 22.8429 26.0143 22.687 25.5205 22.8429L11.6432 27.4687L5.77001 29.4178L0.858364 31.055C0.156701 31.2889 -0.207124 32.0425 0.0527509 32.7182C0.598489 34.1475 2.46959 36.5643 4.80847 39.1631C4.88643 39.267 4.99038 39.371 5.09433 39.4749C5.09433 39.4749 5.09433 39.4749 5.12032 39.5009C6.28976 40.6703 8.03092 40.9562 9.48622 40.3325C9.5382 40.3065 9.61616 40.3065 9.66814 40.3065L13.6442 36.3304C12.5008 35.187 12.5008 33.3419 13.6442 32.1724Z"
          fill="url(#paint2_linear_549_2760)"
        />
        <path
          d="M13.6442 32.1724C13.6962 32.1205 13.7742 32.0425 13.8521 31.9905C14.9956 31.029 16.7367 31.081 17.8022 32.1724L26.8199 23.1548C26.5081 22.8429 26.0143 22.687 25.5205 22.8429L11.6432 27.4687L5.77001 29.4178L0.858364 31.055C0.156701 31.2889 -0.207124 32.0425 0.0527509 32.7182C0.598489 34.1475 2.46959 36.5643 4.80847 39.1631C4.88643 39.267 4.99038 39.371 5.09433 39.4749C5.09433 39.4749 5.09433 39.4749 5.12032 39.5009C6.28976 40.6703 8.03092 40.9562 9.48622 40.3325C9.5382 40.3065 9.61616 40.3065 9.66814 40.3065L13.6442 36.3304C12.5008 35.187 12.5008 33.3419 13.6442 32.1724Z"
          fill="url(#paint3_linear_549_2760)"
        />
        <path
          d="M11.3564 6.62665C5.19739 12.7857 3.35228 21.6474 5.79511 29.4437L11.6683 27.4946C9.92712 21.8294 11.2785 15.4364 15.7483 10.9926C22.1672 4.57364 32.5882 4.57364 38.9812 10.9666L43.3471 6.60067C34.5373 -2.20911 20.1922 -2.20911 11.3564 6.62665Z"
          fill="url(#paint4_linear_549_2760)"
        />
        <path
          d="M6.57485 43.2173L0 50H3.92412C6.08108 50 7.84824 48.2589 7.84824 46.0759C7.82225 44.9325 7.32848 43.9189 6.57485 43.2173Z"
          fill="url(#paint5_linear_549_2760)"
        />
        <path
          d="M3.92412 42.1777C1.74116 42.1777 0 43.9189 0 46.0759V50L6.57485 43.2172C5.87318 42.5675 4.93763 42.1777 3.92412 42.1777Z"
          fill="url(#paint6_linear_549_2760)"
        />
        <path
          d="M3.92412 42.1777C1.74116 42.1777 0 43.9189 0 46.0759V50L6.57485 43.2172C5.87318 42.5675 4.93763 42.1777 3.92412 42.1777Z"
          fill="url(#paint7_linear_549_2760)"
        />
      </g>
      <path
        d="M65.4395 18.1413V0.929199H76.8164V4.16152H68.8045V8.04031H75.7749V11.2726H68.8045V18.1413H65.4395Z"
        fill="black"
      />
      <path
        d="M78.3389 18.1414V5.45458H81.4635V7.15155C82.2647 5.77781 83.3864 5.13135 84.9087 5.13135C85.3894 5.13135 85.9502 5.21216 86.4309 5.37377L86.1906 8.52529C85.6298 8.36367 85.149 8.28286 84.6683 8.28286C82.8256 8.28286 81.6238 9.49498 81.6238 12.1617V18.1414H78.3389Z"
        fill="black"
      />
      <path
        d="M97.0053 5.45458H100.13V18.1415H97.0053V16.6869C95.8836 17.899 94.6017 18.4647 92.9993 18.4647C91.1566 18.4647 89.7144 17.8182 88.5126 16.5253C87.3108 15.2324 86.75 13.6162 86.75 11.7576C86.75 9.89903 87.3108 8.28286 88.5126 6.98993C89.7144 5.697 91.1566 5.13135 92.9993 5.13135C94.6017 5.13135 95.8836 5.77781 97.0053 6.98993V5.45458ZM96.0439 9.17175C95.4029 8.44448 94.5216 8.12125 93.5602 8.12125C92.5186 8.12125 91.7174 8.44448 90.9963 9.17175C90.3554 9.89903 90.0349 10.7879 90.0349 11.7576C90.0349 12.8081 90.3554 13.6162 90.9963 14.3435C91.6373 15.0707 92.5186 15.394 93.48 15.394C94.5216 15.394 95.3228 15.0707 95.9637 14.3435C96.6047 13.6162 97.0053 12.7273 97.0053 11.7576C97.0854 10.7879 96.6848 9.89903 96.0439 9.17175Z"
        fill="black"
      />
      <path
        d="M103.978 16.5253C102.696 15.3132 102.135 13.697 102.135 11.7576C102.135 9.81822 102.776 8.20206 104.058 6.98993C105.34 5.697 106.942 5.13135 108.945 5.13135C111.509 5.13135 113.752 6.42428 114.794 8.6869L112.15 10.1415C111.349 8.84852 110.307 8.20206 108.945 8.20206C107.903 8.20206 107.022 8.52529 106.381 9.25256C105.74 9.89903 105.34 10.7879 105.34 11.8384C105.34 12.8889 105.66 13.7778 106.301 14.4243C106.942 15.0707 107.823 15.4748 108.785 15.4748C110.067 15.4748 111.429 14.6667 111.989 13.5354L114.633 15.0707C113.512 17.0909 111.268 18.4647 108.705 18.4647C106.862 18.3839 105.259 17.8182 103.978 16.5253Z"
        fill="black"
      />
      <path
        d="M125.609 17.414C124.567 18.1413 123.365 18.4646 121.923 18.4646C119.039 18.4646 117.517 16.7676 117.517 13.4545V8.20193H115.434V5.45445H117.517V0.929199H120.721V5.45445H124.888V8.20193H120.721V13.212C120.721 14.6666 121.282 15.4747 122.484 15.4747C123.125 15.4747 123.846 15.2322 124.567 14.8282L125.609 17.414Z"
        fill="black"
      />
      <path
        d="M127.212 3.43434C126.411 2.62626 126.411 1.33333 127.212 0.606061C128.013 -0.20202 129.295 -0.20202 130.097 0.606061C130.898 1.33333 130.898 2.70707 130.097 3.43434C129.295 4.16162 127.933 4.16162 127.212 3.43434ZM130.257 18.1414H126.972V5.45455H130.257V18.1414Z"
        fill="black"
      />
      <path
        d="M134.183 7.07074C135.465 5.77781 137.067 5.13135 139.07 5.13135C141.073 5.13135 142.675 5.77781 143.957 7.07074C145.239 8.36367 145.88 9.89902 145.88 11.8384C145.88 13.697 145.239 15.3132 143.957 16.6061C142.675 17.899 141.073 18.5455 139.07 18.5455C137.067 18.5455 135.465 17.899 134.183 16.6061C132.901 15.3132 132.26 13.7778 132.26 11.8384C132.26 9.89902 132.901 8.28286 134.183 7.07074ZM138.99 15.394C139.951 15.394 140.833 15.0707 141.474 14.3435C142.114 13.6162 142.515 12.7273 142.515 11.7576C142.515 10.7879 142.195 9.89902 141.474 9.17175C140.833 8.44448 140.031 8.12125 138.99 8.12125C138.028 8.12125 137.147 8.44448 136.506 9.17175C135.865 9.89902 135.545 10.7071 135.545 11.7576C135.545 12.8081 135.865 13.6162 136.506 14.3435C137.147 15.0707 138.028 15.394 138.99 15.394Z"
        fill="black"
      />
      <path
        d="M147.803 18.1414V5.45458H151.008V7.23236C151.648 6.02024 153.171 5.13135 154.933 5.13135C156.295 5.13135 157.497 5.6162 158.459 6.50509C159.42 7.39397 159.901 8.6869 159.901 10.3839V18.1414H156.616V11.2728C156.616 9.25256 155.574 8.12125 153.972 8.12125C152.209 8.12125 151.088 9.41418 151.088 11.3536V18.1414H147.803Z"
        fill="black"
      />
      <path
        d="M171.997 18.1415V16.6869C170.876 17.899 169.594 18.4647 167.992 18.4647C166.149 18.4647 164.707 17.8182 163.505 16.5253C162.303 15.2324 161.742 13.6162 161.742 11.7576C161.742 9.89903 162.303 8.28286 163.505 6.98993C164.707 5.697 166.149 5.13135 167.992 5.13135C169.594 5.13135 170.876 5.77781 171.997 6.98993V5.45458H175.122V18.1415H171.997ZM171.036 9.17175C170.395 8.44448 169.514 8.12125 168.552 8.12125C167.511 8.12125 166.71 8.44448 165.989 9.17175C165.348 9.89903 165.027 10.7879 165.027 11.7576C165.027 12.8081 165.348 13.6162 165.989 14.3435C166.629 15.0707 167.511 15.394 168.472 15.394C169.514 15.394 170.315 15.0707 170.956 14.3435C171.597 13.6162 171.997 12.7273 171.997 11.7576C172.078 10.7879 171.757 9.89903 171.036 9.17175Z"
        fill="black"
      />
      <path
        d="M181.131 18.1413H177.846V0.444336H181.131V18.1413Z"
        fill="black"
      />
      <path
        d="M74.493 39.8791L70.8876 33.5761H68.8846V39.8791H65.4395V22.667H72.0093C73.9321 22.667 75.3743 23.2326 76.3357 24.2832C77.2971 25.3337 77.858 26.6266 77.858 28.1619C77.858 30.4246 76.7363 32.364 74.4128 33.2529L78.5791 39.9599H74.493V39.8791ZM68.8045 25.8993V30.5862H71.6087C73.3713 30.5862 74.2526 29.5357 74.2526 28.2428C74.2526 26.9498 73.3713 25.8993 71.9291 25.8993H68.8045Z"
        fill="black"
      />
      <path
        d="M81.1426 28.8083C82.4245 27.5154 84.0269 26.8689 86.0299 26.8689C88.0329 26.8689 89.6353 27.5154 90.9172 28.8083C92.1991 30.1012 92.8401 31.6366 92.8401 33.576C92.8401 35.4346 92.1991 37.0507 90.9172 38.3436C89.6353 39.6366 88.0329 40.283 86.0299 40.283C84.0269 40.283 82.4245 39.6366 81.1426 38.3436C79.8607 37.0507 79.2197 35.5154 79.2197 33.576C79.2998 31.7174 79.9408 30.1012 81.1426 28.8083ZM86.0299 37.2123C86.9913 37.2123 87.8726 36.8891 88.5136 36.1618C89.1546 35.4346 89.5552 34.5457 89.5552 33.576C89.5552 32.6063 89.2347 31.7174 88.5136 30.9901C87.8726 30.2628 87.0714 29.9396 86.0299 29.9396C84.9883 29.9396 84.1871 30.2628 83.5462 30.9901C82.9052 31.7174 82.5848 32.5255 82.5848 33.576C82.5848 34.6265 82.9052 35.4346 83.5462 36.1618C84.1871 36.8083 85.0685 37.2123 86.0299 37.2123Z"
        fill="black"
      />
      <path
        d="M96.0439 38.3436C94.762 37.1315 94.2012 35.5154 94.2012 33.576C94.2012 31.6366 94.8421 30.0204 96.124 28.8083C97.406 27.5154 99.0083 26.9497 101.011 26.9497C103.575 26.9497 105.819 28.2426 106.86 30.5053L104.216 31.9598C103.415 30.6669 102.373 30.0204 101.011 30.0204C99.9698 30.0204 99.0885 30.3436 98.4475 31.0709C97.8065 31.7174 97.406 32.6063 97.406 33.6568C97.406 34.7073 97.7264 35.5962 98.3674 36.2426C99.0083 36.8891 99.8897 37.2931 100.851 37.2931C102.133 37.2931 103.495 36.4851 104.056 35.3537L106.7 36.8891C105.578 38.9093 103.335 40.283 100.771 40.283C98.8481 40.2022 97.2457 39.5558 96.0439 38.3436Z"
        fill="black"
      />
      <path
        d="M116.797 39.8791L112.631 34.465L111.75 35.273V39.9599H108.465V22.1821H111.75V31.5559L116.317 27.1922H120.403L114.794 32.3639L120.723 39.8791H116.797Z"
        fill="black"
      />
      <path
        d="M132.981 34.7073H123.527C124.008 36.4851 125.21 37.3739 127.213 37.3739C128.575 37.3739 130.177 36.8083 131.299 36.081L132.581 38.4245C130.978 39.5558 129.135 40.2022 127.052 40.2022C124.809 40.2022 123.126 39.5558 122.005 38.2628C120.803 36.9699 120.242 35.4346 120.242 33.576C120.242 31.6366 120.803 30.0204 122.085 28.8083C123.287 27.5154 124.889 26.9497 126.812 26.9497C128.655 26.9497 130.177 27.5154 131.379 28.6467C132.581 29.778 133.141 31.3133 133.141 33.3335C133.141 33.7376 133.061 34.2224 132.981 34.7073ZM123.527 32.283H129.937C129.616 30.6669 128.495 29.6164 126.812 29.6164C125.21 29.6164 123.928 30.5861 123.527 32.283Z"
        fill="black"
      />
      <path
        d="M144.037 39.1518C142.995 39.8791 141.793 40.2023 140.351 40.2023C137.467 40.2023 135.944 38.5054 135.944 35.1922V29.9397H133.861V27.1922H135.944V22.667H139.149V27.1922H143.315V29.9397H139.149V34.9498C139.149 36.4044 139.71 37.2124 140.912 37.2124C141.553 37.2124 142.274 36.97 142.995 36.566L144.037 39.1518Z"
        fill="black"
      />
      <defs>
        <linearGradient
          id="paint0_linear_549_2760"
          x1="44.7722"
          y1="5.22127"
          x2="0.569317"
          y2="49.4241"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF473A" />
          <stop offset="0.7414" stopColor="#CB2D3E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_549_2760"
          x1="62.4643"
          y1="-13.0931"
          x2="-38.2932"
          y2="88.8498"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3827" stopColor="#EF473A" />
          <stop offset="0.8792" stopColor="#CB2D3E" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_549_2760"
          x1="-3.30336"
          y1="12.6038"
          x2="30.7759"
          y2="45.6965"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3827" stopColor="#EF473A" />
          <stop offset="0.8792" stopColor="#CB2D3E" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_549_2760"
          x1="11.9279"
          y1="26.878"
          x2="23.5172"
          y2="39.6174"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF473A" />
          <stop offset="0.7414" stopColor="#CB2D3E" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_549_2760"
          x1="14.1706"
          y1="2.24802"
          x2="35.7568"
          y2="34.9813"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF473A" />
          <stop offset="0.7414" stopColor="#CB2D3E" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_549_2760"
          x1="-0.0556395"
          y1="52.5083"
          x2="8.22082"
          y2="44.4802"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.4193" stopColor="#EF473A" />
          <stop offset="1" stopColor="#CB2D3E" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_549_2760"
          x1="-1.51749"
          y1="40.9109"
          x2="4.44155"
          y2="47.9734"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.4413" stopColor="#EF473A" />
          <stop offset="1" stopColor="#CB2D3E" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_549_2760"
          x1="-3.37768"
          y1="41.5054"
          x2="7.83565"
          y2="50.0647"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF473A" />
          <stop offset="0.7414" stopColor="#CB2D3E" />
        </linearGradient>
        <clipPath id="clip0_549_2760">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
